var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "home"
  }, [_c('b-container', {
    staticClass: "mb-3 table-font",
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "align-self": "flex-start",
      "cursor": "pointer"
    },
    on: {
      "click": _vm.setBlocked
    }
  }, [_c('b-checkbox', {
    staticClass: "p-0",
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.showBlocked,
      callback: function ($$v) {
        _vm.showBlocked = $$v;
      },
      expression: "showBlocked"
    }
  }), _vm._v(" Показывать заблокированные ")], 1), _c('b-card', {
    staticStyle: {
      "height": "86vh",
      "padding-bottom": "0 !important"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('b-table', {
    staticStyle: {
      "height": "85vh",
      "max-height": "85vh",
      "margin-bottom": "0"
    },
    attrs: {
      "sticky-header": "",
      "fields": _vm.table.fields,
      "items": _vm.tableData,
      "label-sort-asc": "",
      "label-sort-desc": "",
      "label-sort-clear": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "busy": _vm.table.isBusy
    },
    on: {
      "sort-changed": _vm.handleSort
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle"
        }), _c('strong', [_vm._v("Загрузка...")])], 1)];
      },
      proxy: true
    }, {
      key: "cell(email)",
      fn: function (_ref) {
        var _item$user$email, _item$user;

        var item = _ref.item;
        return [_c('p', {
          staticStyle: {
            "margin": "0",
            "cursor": "pointer"
          },
          on: {
            "click": function ($event) {
              return _vm.generateStorage(item.user.email);
            }
          }
        }, [_vm._v(" " + _vm._s((_item$user$email = (_item$user = item.user) === null || _item$user === void 0 ? void 0 : _item$user.email) !== null && _item$user$email !== void 0 ? _item$user$email : "-") + " ")])];
      }
    }, {
      key: "cell(phone)",
      fn: function (_ref2) {
        var _item$user$phone, _item$user2;

        var item = _ref2.item;
        return [_c('p', {
          staticStyle: {
            "margin": "0",
            "cursor": "pointer"
          },
          on: {
            "click": function ($event) {
              return _vm.generateStorage(item.user.phone);
            }
          }
        }, [_vm._v(" " + _vm._s((_item$user$phone = (_item$user2 = item.user) === null || _item$user2 === void 0 ? void 0 : _item$user2.phone) !== null && _item$user$phone !== void 0 ? _item$user$phone : "-") + " ")])];
      }
    }, {
      key: "cell(lastActivity)",
      fn: function (_ref3) {
        var _item$user3;

        var item = _ref3.item;
        return [_c('p', {
          staticStyle: {
            "margin": "0"
          }
        }, [_vm._v(" " + _vm._s((_item$user3 = item.user) !== null && _item$user3 !== void 0 && _item$user3.lastActivity ? _vm.formatDateTime(item.user.lastActivity) : "-") + " ")])];
      }
    }, {
      key: "cell(active)",
      fn: function (_ref4) {
        var _item$cash_integratio;

        var item = _ref4.item;
        return [_c('p', {
          staticStyle: {
            "margin": "0"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "checked": (_item$cash_integratio = item.cash_integration) === null || _item$cash_integratio === void 0 ? void 0 : _item$cash_integratio.active,
            "disabled": ""
          }
        })], 1)];
      }
    }, {
      key: "cell(cancel_date)",
      fn: function (_ref5) {
        var _item$cash_integratio2, _item$cash_integratio3;

        var item = _ref5.item;
        return [_c('p', {
          staticStyle: {
            "margin": "0"
          }
        }, [_vm._v(" " + _vm._s((_item$cash_integratio2 = item.cash_integration) !== null && _item$cash_integratio2 !== void 0 && _item$cash_integratio2.cancel_date ? _vm.formatDateTime((_item$cash_integratio3 = item.cash_integration) === null || _item$cash_integratio3 === void 0 ? void 0 : _item$cash_integratio3.cancel_date) : "-") + " ")])];
      }
    }, {
      key: "cell(block)",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [!item.blocked ? _c('b-icon', {
          staticStyle: {
            "cursor": "pointer",
            "color": "#dc3545"
          },
          attrs: {
            "icon": "lock-fill",
            "scale": "1.3"
          },
          on: {
            "click": function ($event) {
              return _vm.blockCompany(item.id);
            }
          }
        }) : _vm._e(), item.blocked ? _c('b-icon', {
          staticStyle: {
            "cursor": "pointer",
            "color": "#00ff3b"
          },
          attrs: {
            "icon": "unlock-fill",
            "scale": "1.3"
          },
          on: {
            "click": function ($event) {
              return _vm.blockCompany(item.id);
            }
          }
        }) : _vm._e()];
      }
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }