<template>
  <div class="home">
    <b-container class="mb-3 table-font" fluid>
      <div class="filters-block">
        <div
          style="
            display: flex;
            flex-direction: row;
            align-self: flex-start;
            cursor: pointer;
          "
          @click="setBlocked"
        >
          <b-checkbox switch class="p-0" v-model="showBlocked"></b-checkbox>
          Показывать заблокированные
        </div>
        <div
          style="
            margin-left: 12px;
            display: flex;
            flex-direction: row;
            align-self: flex-start;
            cursor: pointer;
          "
        >
          <b-checkbox switch class="p-0" v-model="isFasol">Фасоль</b-checkbox>
        </div>
        <div
          style="
            margin-left: 12px;
            display: flex;
            flex-direction: row;
            align-self: flex-start;
            cursor: pointer;
          "
        >
          <b-checkbox switch class="p-0" v-model="isAlive">Активные</b-checkbox>
        </div>
      </div>

      <b-card no-body style="height: 86vh; padding-bottom: 0 !important">
        <resizable-table
          sticky-header
          :default_fields="table.fields"
          :items="companies"
          label-sort-asc=""
          label-sort-desc=""
          label-sort-clear=""
          @sort_change="handleSort"
          small
          hover
          responsive
          :busy="table.isBusy"
          table_name="sss"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Загрузка...</strong>
            </div>
          </template>

          <template #body_number="data">
            <div>
              <div
                class="b-status"
                :class="{ success: data.item.is_alive }"
              ></div>
              <p style="margin: 0; cursor: pointer">
                {{ data.index + 1 ?? "-" }}
              </p>
            </div>
          </template>

          <template #body_like="{ item }">
            <div v-if="!(typeof item?.like === 'boolean')">-</div>
            <div v-else>
              {{ item?.like ? "🟢" : "🔴" }}
            </div>
          </template>

          <template #body_email="{ item }">
            <span
              class="d-flex"
              style="margin: 0; cursor: pointer"
              @click="generateStorage(item.user.email)"
            >
              {{ item.user?.email ?? "-" }}
            </span>
          </template>

          <template #body_name="{ item }">
            <span @click="copyStorage(item.user.email)" class="d-flex">
              {{ item.name ?? "-" }}
            </span>
          </template>

          <template #body_phone="{ item }">
            <p
              style="margin: 0; cursor: pointer"
              @click="generateStorage(item.user.phone)"
            >
              {{ item.user?.phone ?? "-" }}
            </p>
          </template>

          <template #body_createdDate="{ item }">
            <p style="margin: 0">
              {{ item?.createdDate ? formatDateTime(item.createdDate) : "-" }}
            </p>
          </template>

          <template #body_lastActivity="{ item }">
            <p style="margin: 0">
              {{
                item.user?.lastActivity
                  ? formatDateTime(item.user.lastActivity)
                  : "-"
              }}
            </p>
          </template>

          <template #body_license_end="{ item }">
            <div class="d-flex" style="text-align: left">
              <div :id="'to-' + item.id" v-if="item.branches?.length > 1">
                Оплачено:
                <span style="color: #888">{{
                  reduce_date(item.branches)
                }}</span>
              </div>
              <div v-else>
                {{ item.branches?.[0]?.name }}:
                <span style="color: #888">{{
                  formatDate(item.branches?.[0]?.subscription?.cancel_date)
                }}</span>
              </div>
              <b-tooltip :target="'to-' + item.id" triggers="hover">
                <div>
                  <div v-for="br of item.branches" :key="br.id">
                    {{ br?.name }}:
                    {{ formatDate(br?.subscription?.cancel_date) }}
                  </div>
                </div>
              </b-tooltip>
            </div>
          </template>

          <template #body_branches_count="{ item }">
            {{ item.branches_count ?? 0 }}
          </template>

          <template #body_mercury="{ item }">
            {{ item.mercury ? "✅" : "-" }}
          </template>

          <template #body_egais="{ item }">
            {{ item.egais ? "✅" : "-" }}
          </template>

          <template #body_crptech="{ item }">
            {{ item.dirty_space ? "✅" : "-" }}
          </template>

          <template #body_cashes="{ item }">
            {{ getCashes(item.cashes) }}
          </template>

          <template #body_block="{ item }">
            <b-icon
              icon="lock-fill"
              v-if="!item.blocked"
              style="cursor: pointer; color: #dc3545"
              @click="blockCompany(item.id)"
              scale="1.3"
            />
            <b-icon
              icon="unlock-fill"
              v-if="item.blocked"
              style="cursor: pointer; color: #00ff3b"
              @click="blockCompany(item.id)"
              scale="1.3"
            />
          </template>
        </resizable-table>
      </b-card>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src

import gql from "graphql-tag";

export default {
  name: "HomeView",
  components: {},

  apollo: {
    AdmCompaniesV2: {
      query: gql`
        query AdmComp($blocked: Boolean!, $franchise: String) {
          AdmCompaniesV2(blocked: $blocked, franchise: $franchise) {
            companies {
              id
              name
              like
              user {
                email
                phone
                lastActivity
              }
              branches {
                name
                subscription {
                  cancel_date
                }
              }
              blocked
              branches_count
              createdDate
              checkAnalytics {
                average
                quantity
                returns
                revenue
              }
              mercury
              egais
              dirty_space
              cashes
              is_alive
              license_end
              analyticsMargin {
                totalSum {
                  margin
                  avgMargin
                }
              }
            }
            total_alive
          }
        }
      `,
      variables: function () {
        return {
          blocked: this.showBlocked,
          franchise: this.isFasol ? "fasol" : "",
        };
      },
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
      result({ data }) {
        this.copy_companies = data?.AdmCompaniesV2?.companies ?? [];
        this.total_items = data?.AdmCompaniesV2?.total_alive ?? 0;
        this.table.isBusy = false;
      },
      error() {
        alert("Ошибка получения");
      },
    },
  },

  data() {
    return {
      isAlive: false,
      total_items: 0,
      isFasol: false,
      table: {
        fields: [
          {
            key: "number",
            label: "№",
            thClass: "align-middle",
            style: "",
            class: "position-relative text-center",
            checked: true,
          },
          { key: "like", label: "👍", thClass: "align-middle", checked: true },
          {
            key: "name",
            label: "Название компании",
            width: 300,
            thClass: "align-middle",
            style: "width: 50px",
            checked: true,
          },
          {
            key: "email",
            width: 200,
            label: "Email",
            thClass: "align-middle",
            checked: true,
          },
          {
            key: "phone",
            width: 200,
            label: "Тел",
            thClass: "align-middle",
            checked: true,
          },
          {
            key: "createdDate",
            label: "Рег.",
            width: 200,
            sortable: true,
            thClass: "align-middle",
            checked: true,
          },
          {
            key: "lastActivity",
            label: "Послед.актив",
            width: 200,
            thClass: "align-middle",
            checked: true,
          },
          {
            key: "license_end",
            width: 200,
            label: "Оплаченные магазины",
            thClass: "align-middle",
            checked: true,
          },
          {
            key: "branches_count",
            label: "Кол-во филиалов",
            thClass: "align-middle",
            checked: true,
          },
          {
            key: "egais",
            label: "ЕГАИС",
            thClass: "align-middle",
            checked: true,
          },
          {
            key: "mercury",
            label: "Меркурий",
            thClass: "align-middle",
            checked: true,
          },
          {
            key: "crptech",
            label: "Честный знак",
            thClass: "align-middle",
            checked: true,
          },
          {
            key: "cashes",
            label: "Касса",
            thClass: "align-middle",
            checked: true,
          },
          {
            key: "block",
            label: "Блок",
            thClass: "align-middle",
            checked: true,
          },
        ],
        isBusy: true,
      },
      copy_companies: [],
      showBlocked: false,
    };
  },
  watch: {
    isFasol() {
      this.table.isBusy = true;
    },
  },
  methods: {
    reduce_date(inc_branches) {
      const date = new Date();
      const branches = inc_branches?.filter(
        (el) => new Date(el?.subscription?.cancel_date) > date
      );
      return branches?.length + "/" + inc_branches?.length;
    },
    getCashes(cashes) {
      if (!cashes?.length) return "-";
      const str = cashes.reduce((acc, obj, i, arr) => {
        if (i === arr.length - 1) return acc + obj;
        return acc + obj + ", ";
      }, "");
      return str ? str : "-";
    },
    handleSort(ctx) {
      let a1 = 1;
      let b2 = -1;
      if (ctx.sort === "DESC") {
        a1 = -1;
        b2 = 1;
      }
      if (ctx.key === "createdDate")
        this.companies.sort((a, b) =>
          a.createdDate > b.createdDate ? a1 : b2
        );
      if (ctx.key === "revenue")
        this.companies.sort((a, b) =>
          a.checkAnalytics?.revenue > b.checkAnalytics?.revenue ? a1 : b2
        );
      if (ctx.key === "margin")
        this.companies.sort((a, b) =>
          a.margin?.margin > b.margin?.margin ? a1 : b2
        );
      if (ctx.key === "avgMargin")
        this.companies.sort((a, b) =>
          a.analyticsMargin?.totalSum?.avgMargin >
          b.analyticsMargin?.totalSum?.avgMargin
            ? a1
            : b2
        );
      if (ctx.key === "margin_total")
        this.companies.sort((a, b) =>
          a.analyticsMargin?.totalSum?.margin >
          b.analyticsMargin?.totalSum?.margin
            ? a1
            : b2
        );
      if (ctx.key === "quantity")
        this.companies.sort((a, b) =>
          a.checkAnalytics?.quantity > b.checkAnalytics?.quantity ? a1 : b2
        );
      if (ctx.key === "average")
        this.companies.sort((a, b) =>
          a.checkAnalytics?.average > b.checkAnalytics?.average ? a1 : b2
        );
    },
    formatDate(date) {
      return date
        ? new Intl.DateTimeFormat("ru-RU").format(new Date(date))
        : "-";
    },

    formatDateTime(date) {
      return new Intl.DateTimeFormat("ru-RU", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format(new Date(date));
    },

    setBlocked() {
      this.showBlocked = !this.showBlocked;
    },
    async blockCompany(id) {
      await this.$apollo.mutate({
        mutation: gql`
          mutation BlockCompany($id: ID!) {
            BlockCompany(id: $id) {
              status
            }
          }
        `,
        variables: { id: id },
      });
      await this.$apollo.queries.AdmCompaniesV2.refetch();
    },
    async generateStorage(email) {
      try {
        const { data } = await this.$apollo.query({
          query: gql`
            query LocalStorage($email: String!) {
              LocalStorage(email: $email, phone: $email)
            }
          `,
          variables: {
            email,
          },
        });
        window.open(
          "https://online.entersight.ru/setToken?token=" +
            JSON.parse(data.LocalStorage)?.core?.token
        );
        this.$notify({
          group: "foo",
          type: "success",
          title: "Успешно",
          text: "LocalStorage скопирован",
        });
      } catch (e) {
        console.log(e);

        this.$notify({
          group: "foo",
          type: "error",
          title: "Ошибка",
          text: "Ошибка создания LocalStorage",
        });
      }
    },
    async copyStorage(email) {
      try {
        const { data } = await this.$apollo.query({
          query: gql`
            query LocalStorage($email: String!) {
              LocalStorage(email: $email, phone: $email)
            }
          `,
          variables: {
            email,
          },
        });
        await navigator.clipboard.writeText(
          "https://online.entersight.ru/setToken?token=" +
            JSON.parse(data.LocalStorage)?.core?.token
        );

        this.$notify({
          group: "foo",
          type: "success",
          title: "Успешно",
          text: "LocalStorage скопирован",
        });
      } catch (e) {
        console.log(e);

        this.$notify({
          group: "foo",
          type: "error",
          title: "Ошибка",
          text: "Ошибка создания LocalStorage",
        });
      }
    },
  },
  computed: {
    tableData() {
      return this.companies;
    },
    companies() {
      if (this.isAlive) {
        return this.copy_companies.filter((obj) => obj.is_alive === true);
      } else {
        return this.copy_companies;
      }
    },
  },
};
</script>

<style lang="scss">
.b-status {
  width: 2px !important;
  height: 32px !important;
  position: absolute;
  left: 0px;
  top: 50%;
  background: #bcbcbc;
  border-radius: 0px 1px 1px 0px;
  transform: translate(0%, -50%);

  &.success {
    background: #00cb91;
  }
}
.table-responsive {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 10px;
  }
}
.table-font * {
  font-size: 14px;
}

table {
  border-collapse: separate !important;
  border-spacing: 0 !important;
}

thead {
  position: sticky;
  top: -1px;
  left: 0;
  background: #fff;
  z-index: 222;
}

.filters-block {
  display: flex;
}
.td-inner {
  position: relative;
}
.row {
  display: table-row !important;
}
.row-field {
  position: relative;
}
</style>
