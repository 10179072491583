<template>
  <div id="app">
    <notifications group="foo" position="bottom right" />
    <nav>
      <div
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        "
      >
        <div v-if="isLogged">
          <router-link to="/" style="margin-right: 15px">Главная</router-link>
          <router-link to="/analytics">Анал</router-link>
          <!--          <router-link to="/about">About</router-link> |-->
        </div>
        <b-button variant="danger" @click="logout" v-if="isLogged"
          >Выйти</b-button
        >
      </div>
    </nav>
    <router-view />
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  methods: {
    ...mapActions({
      logout_state: "auth/logout",
    }),
    async logout() {
      this.logout_state();
      await this.$router.push("/login");
    },
  },
  computed: {
    ...mapGetters({
      isLogged: "auth/isLoggedIn",
    }),
  },
};
</script>
