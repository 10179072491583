var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "home"
  }, [_c('b-container', {
    staticClass: "mb-3 table-font",
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "filters-block"
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "align-self": "flex-start",
      "cursor": "pointer"
    },
    on: {
      "click": _vm.setBlocked
    }
  }, [_c('b-checkbox', {
    staticClass: "p-0",
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.showBlocked,
      callback: function ($$v) {
        _vm.showBlocked = $$v;
      },
      expression: "showBlocked"
    }
  }), _vm._v(" Показывать заблокированные ")], 1), _c('div', {
    staticStyle: {
      "margin-left": "12px",
      "display": "flex",
      "flex-direction": "row",
      "align-self": "flex-start",
      "cursor": "pointer"
    }
  }, [_c('b-checkbox', {
    staticClass: "p-0",
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.isFasol,
      callback: function ($$v) {
        _vm.isFasol = $$v;
      },
      expression: "isFasol"
    }
  }, [_vm._v("Фасоль")])], 1), _c('div', {
    staticStyle: {
      "margin-left": "12px",
      "display": "flex",
      "flex-direction": "row",
      "align-self": "flex-start",
      "cursor": "pointer"
    }
  }, [_c('b-checkbox', {
    staticClass: "p-0",
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.isAlive,
      callback: function ($$v) {
        _vm.isAlive = $$v;
      },
      expression: "isAlive"
    }
  }, [_vm._v("Активные")])], 1)]), _c('b-card', {
    staticStyle: {
      "height": "86vh",
      "padding-bottom": "0 !important"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('resizable-table', {
    attrs: {
      "sticky-header": "",
      "default_fields": _vm.table.fields,
      "items": _vm.companies,
      "label-sort-asc": "",
      "label-sort-desc": "",
      "label-sort-clear": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "busy": _vm.table.isBusy,
      "table_name": "sss"
    },
    on: {
      "sort_change": _vm.handleSort
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle"
        }), _c('strong', [_vm._v("Загрузка...")])], 1)];
      },
      proxy: true
    }, {
      key: "body_number",
      fn: function (data) {
        var _ref;

        return [_c('div', [_c('div', {
          staticClass: "b-status",
          class: {
            success: data.item.is_alive
          }
        }), _c('p', {
          staticStyle: {
            "margin": "0",
            "cursor": "pointer"
          }
        }, [_vm._v(" " + _vm._s((_ref = data.index + 1) !== null && _ref !== void 0 ? _ref : "-") + " ")])])];
      }
    }, {
      key: "body_like",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [!(typeof (item === null || item === void 0 ? void 0 : item.like) === 'boolean') ? _c('div', [_vm._v("-")]) : _c('div', [_vm._v(" " + _vm._s(item !== null && item !== void 0 && item.like ? "🟢" : "🔴") + " ")])];
      }
    }, {
      key: "body_email",
      fn: function (_ref3) {
        var _item$user$email, _item$user;

        var item = _ref3.item;
        return [_c('span', {
          staticClass: "d-flex",
          staticStyle: {
            "margin": "0",
            "cursor": "pointer"
          },
          on: {
            "click": function ($event) {
              return _vm.generateStorage(item.user.email);
            }
          }
        }, [_vm._v(" " + _vm._s((_item$user$email = (_item$user = item.user) === null || _item$user === void 0 ? void 0 : _item$user.email) !== null && _item$user$email !== void 0 ? _item$user$email : "-") + " ")])];
      }
    }, {
      key: "body_name",
      fn: function (_ref4) {
        var _item$name;

        var item = _ref4.item;
        return [_c('span', {
          staticClass: "d-flex",
          on: {
            "click": function ($event) {
              return _vm.copyStorage(item.user.email);
            }
          }
        }, [_vm._v(" " + _vm._s((_item$name = item.name) !== null && _item$name !== void 0 ? _item$name : "-") + " ")])];
      }
    }, {
      key: "body_phone",
      fn: function (_ref5) {
        var _item$user$phone, _item$user2;

        var item = _ref5.item;
        return [_c('p', {
          staticStyle: {
            "margin": "0",
            "cursor": "pointer"
          },
          on: {
            "click": function ($event) {
              return _vm.generateStorage(item.user.phone);
            }
          }
        }, [_vm._v(" " + _vm._s((_item$user$phone = (_item$user2 = item.user) === null || _item$user2 === void 0 ? void 0 : _item$user2.phone) !== null && _item$user$phone !== void 0 ? _item$user$phone : "-") + " ")])];
      }
    }, {
      key: "body_createdDate",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('p', {
          staticStyle: {
            "margin": "0"
          }
        }, [_vm._v(" " + _vm._s(item !== null && item !== void 0 && item.createdDate ? _vm.formatDateTime(item.createdDate) : "-") + " ")])];
      }
    }, {
      key: "body_lastActivity",
      fn: function (_ref7) {
        var _item$user3;

        var item = _ref7.item;
        return [_c('p', {
          staticStyle: {
            "margin": "0"
          }
        }, [_vm._v(" " + _vm._s((_item$user3 = item.user) !== null && _item$user3 !== void 0 && _item$user3.lastActivity ? _vm.formatDateTime(item.user.lastActivity) : "-") + " ")])];
      }
    }, {
      key: "body_license_end",
      fn: function (_ref8) {
        var _item$branches, _item$branches2, _item$branches2$, _item$branches3, _item$branches3$, _item$branches3$$subs;

        var item = _ref8.item;
        return [_c('div', {
          staticClass: "d-flex",
          staticStyle: {
            "text-align": "left"
          }
        }, [((_item$branches = item.branches) === null || _item$branches === void 0 ? void 0 : _item$branches.length) > 1 ? _c('div', {
          attrs: {
            "id": 'to-' + item.id
          }
        }, [_vm._v(" Оплачено: "), _c('span', {
          staticStyle: {
            "color": "#888"
          }
        }, [_vm._v(_vm._s(_vm.reduce_date(item.branches)))])]) : _c('div', [_vm._v(" " + _vm._s((_item$branches2 = item.branches) === null || _item$branches2 === void 0 ? void 0 : (_item$branches2$ = _item$branches2[0]) === null || _item$branches2$ === void 0 ? void 0 : _item$branches2$.name) + ": "), _c('span', {
          staticStyle: {
            "color": "#888"
          }
        }, [_vm._v(_vm._s(_vm.formatDate((_item$branches3 = item.branches) === null || _item$branches3 === void 0 ? void 0 : (_item$branches3$ = _item$branches3[0]) === null || _item$branches3$ === void 0 ? void 0 : (_item$branches3$$subs = _item$branches3$.subscription) === null || _item$branches3$$subs === void 0 ? void 0 : _item$branches3$$subs.cancel_date)))])]), _c('b-tooltip', {
          attrs: {
            "target": 'to-' + item.id,
            "triggers": "hover"
          }
        }, [_c('div', _vm._l(item.branches, function (br) {
          var _br$subscription;

          return _c('div', {
            key: br.id
          }, [_vm._v(" " + _vm._s(br === null || br === void 0 ? void 0 : br.name) + ": " + _vm._s(_vm.formatDate(br === null || br === void 0 ? void 0 : (_br$subscription = br.subscription) === null || _br$subscription === void 0 ? void 0 : _br$subscription.cancel_date)) + " ")]);
        }), 0)])], 1)];
      }
    }, {
      key: "body_branches_count",
      fn: function (_ref9) {
        var _item$branches_count;

        var item = _ref9.item;
        return [_vm._v(" " + _vm._s((_item$branches_count = item.branches_count) !== null && _item$branches_count !== void 0 ? _item$branches_count : 0) + " ")];
      }
    }, {
      key: "body_mercury",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_vm._v(" " + _vm._s(item.mercury ? "✅" : "-") + " ")];
      }
    }, {
      key: "body_egais",
      fn: function (_ref11) {
        var item = _ref11.item;
        return [_vm._v(" " + _vm._s(item.egais ? "✅" : "-") + " ")];
      }
    }, {
      key: "body_crptech",
      fn: function (_ref12) {
        var item = _ref12.item;
        return [_vm._v(" " + _vm._s(item.dirty_space ? "✅" : "-") + " ")];
      }
    }, {
      key: "body_cashes",
      fn: function (_ref13) {
        var item = _ref13.item;
        return [_vm._v(" " + _vm._s(_vm.getCashes(item.cashes)) + " ")];
      }
    }, {
      key: "body_block",
      fn: function (_ref14) {
        var item = _ref14.item;
        return [!item.blocked ? _c('b-icon', {
          staticStyle: {
            "cursor": "pointer",
            "color": "#dc3545"
          },
          attrs: {
            "icon": "lock-fill",
            "scale": "1.3"
          },
          on: {
            "click": function ($event) {
              return _vm.blockCompany(item.id);
            }
          }
        }) : _vm._e(), item.blocked ? _c('b-icon', {
          staticStyle: {
            "cursor": "pointer",
            "color": "#00ff3b"
          },
          attrs: {
            "icon": "unlock-fill",
            "scale": "1.3"
          },
          on: {
            "click": function ($event) {
              return _vm.blockCompany(item.id);
            }
          }
        }) : _vm._e()];
      }
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }