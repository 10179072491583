var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', [_c('div', {
    staticClass: "login"
  }, [_c('div', {
    staticClass: "mt-lg-5",
    staticStyle: {
      "font-weight": "500",
      "font-size": "32px"
    }
  }, [_vm._v(" Авторизация ")]), _c('b-form-input', {
    staticClass: "number-input",
    staticStyle: {
      "margin-top": "48px",
      "border-radius": "4.8px"
    },
    attrs: {
      "placeholder": "Email"
    },
    on: {
      "input": _vm.validateMail
    },
    model: {
      value: _vm.user.email,
      callback: function ($$v) {
        _vm.$set(_vm.user, "email", $$v);
      },
      expression: "user.email"
    }
  }), _c('b-form-input', {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      "placeholder": "Пароль",
      "type": "password"
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.authorize.apply(null, arguments);
      },
      "input": _vm.validateMail
    },
    model: {
      value: _vm.user.password,
      callback: function ($$v) {
        _vm.$set(_vm.user, "password", $$v);
      },
      expression: "user.password"
    }
  }), _c('div', {
    staticStyle: {
      "text-align": "start",
      "margin-top": "4px",
      "display": "flex",
      "flex-direction": "row"
    }
  }), _c('b-button', {
    staticStyle: {
      "margin-top": "40px"
    },
    on: {
      "click": _vm.authorize
    }
  }, [_vm._v("Войти")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }