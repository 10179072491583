import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import Login from "@/views/auth/Login";
import store from "@/store";
import Vue from "vue";
import NoAnalyticsView from "@/views/NoAnalyticsView";
import CompanyAnalytics from "@/views/CompanyAnalytics.vue";

Vue.use(VueRouter);

const DEFAULT_TITLE = "ЭнтерАдмин";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/analytics",
    name: "home",
    component: CompanyAnalytics,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  { path: "/login", name: "login", component: Login },
  { path: "/no_anal", name: "no_anal", component: NoAnalyticsView },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(async (to, from, next) => {
  // console.log(auth.getters.isLoggedIn())
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["auth/isLoggedIn"]) {
      return next();
    }
    next({ name: "login" });
  } else {
    next();
  }
});

router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.label || DEFAULT_TITLE;
  });
});

export default router;
