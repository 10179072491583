export const tooltipBuilder = (context) => {
  // Tooltip Element
  let tooltipEl = document.getElementById("chartjs-tooltip");

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.id = "chartjs-tooltip";
    tooltipEl.innerHTML = "<div style='z-index: 1000'></div>";
    document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  const tooltipModel = context.tooltip;
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }
  // Set caret Position
  tooltipEl.classList.remove("above", "below", "no-transform");
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add("no-transform");
  }
  const raw = context.tooltip.dataPoints[0].raw;
  // Set Text
  if (tooltipModel.body) {
    let addHtml = `
<div>${raw.x}</div>
<div>${raw.y}</div>
`;
    for (const inc of raw.franchise.inc || []) {
      addHtml += `<div>+ ${inc.franchise || "free"}: ${inc.count}</div>`;
      for (const cmp of inc.cmps || []) {
        addHtml += `<div style="margin-left: 20px">${cmp.name}</div>`;
      }
    }

    addHtml += "<br/>";
    for (const out of raw.franchise.out || []) {
      addHtml += `<div>- ${out.franchise || "free"}: ${out.count}</div>`;
      for (const cmp of out.cmps || []) {
        addHtml += `<div style="margin-left: 20px">${cmp.name}</div>`;
      }
    }
    let innerHtml = `
<div style="background: rgba(0,0,0,0.8);color: white; border-radius: 5px; padding: 5px; flex-direction: column; display: flex; z-index: 1000">
${addHtml}

</div>`;

    let tableRoot = tooltipEl.querySelector("div");
    tableRoot.innerHTML = innerHtml;
  }

  const position = context.chart.canvas.getBoundingClientRect();

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.position = "absolute";
  tooltipEl.style.left =
    position.left + window.pageXOffset + tooltipModel.caretX + 15 + "px";
  tooltipEl.style.top =
    position.top + window.pageYOffset + tooltipModel.caretY - 10 + "px";
  tooltipEl.style.padding =
    tooltipModel.padding + "px " + tooltipModel.padding + "px";
  tooltipEl.style.pointerEvents = "none";
};
