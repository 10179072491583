import Vuex from "vuex";
import auth from "@/store/auth/auth";
import Vue from "vue";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth: auth,
  },
});
