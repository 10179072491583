var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Bar', {
    attrs: {
      "id": "my-chart-id",
      "options": _vm.chartOptions,
      "data": _vm.chartData
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }