<template>
  <div class="home">
    <b-container class="mb-3 table-font" fluid>
      <div class="d-flex mb-3" style="margin-right: 16px">
        <b-card class="widget">
          <div>Кол-во аккаунтов</div>
          <div class="counter">{{ widget.cmp_count }}</div>
        </b-card>
        <b-card class="widget">
          <div>Кол-во магазинов</div>
          <div class="counter">{{ widget.br_count }}</div>
        </b-card>
        <b-card class="widget">
          <div>Кол-во подписаных магазинов</div>
          <div class="counter">{{ widget.br_sub_active }}</div>
        </b-card>
        <b-card class="widget">
          <div>Кол-во активных аккаунтов</div>
          <div class="counter">{{ widget.cmp_active }}</div>
        </b-card>
        <b-card class="widget">
          <div>Средний LTV</div>
          <div class="counter">6</div>
        </b-card>
      </div>
      <div class="d-flex">
        <div style="width: 500px; height: 200px; z-index: 3">
          <BarChart :chart-data="subChartData" :plugins="sub_plugin" />
        </div>
        <div style="width: 500px; height: 200px; z-index: 3">
          <BarChart :chart-data="branchChartData" :plugins="plugin" />
        </div>
      </div>

      <b-card
        no-body
        style="height: 86vh; padding-bottom: 0 !important"
        class="anal-table"
      >
        <div style="display: flex" class="p-3 align-items-center">
          <div
            class="d-flex flex-column align-items-start"
            style="margin-right: 16px"
          >
            <b-checkbox> Показывать демо</b-checkbox>
          </div>
          <div style="margin-right: 16px">
            <b-checkbox> Показывать активные</b-checkbox>
          </div>
          <div class="d-flex flex-column align-items-start">
            Окончание после:
            <b-input
              type="date"
              :value="dateFormat"
              autofocus
              @input="changeDate"
            ></b-input>
          </div>
        </div>
        <b-table
          sticky-header
          :fields="table.fields"
          :items="companies"
          label-sort-asc=""
          label-sort-desc=""
          label-sort-clear=""
          @sort_change="handleSort"
          @row-clicked="rowClicked"
          small
          hover
          bordered
          striped
          responsive
          :busy="table.isBusy"
          table_name="sss4234ы"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Загрузка...</strong>
            </div>
          </template>

          <template #cell(number)="data">
            <div>
              <div
                class="b-status"
                :class="{ success: data.item.is_alive }"
              ></div>
              <p style="margin: 0; cursor: pointer">
                {{ data.index + 1 ?? "-" }}
              </p>
            </div>
          </template>
          <template #cell(franchise)="{ item }">
            <div>
              {{ item.company ? "" : item.franchise }}
            </div>
            <div v-if="show_franhise_input === item.branch?.id">
              <b-select
                :options="franchise_options"
                v-model="item.franchise"
                @input="
                  (value) => updateBranchFranchise(value, item.branch?.id)
                "
              />
            </div>
          </template>
          <template #cell(company)="{ item }">
            <div>
              {{ item.branch ? "" : item.company?.name }}
            </div>
          </template>
          <template #cell(branch)="{ item }">
            <div>
              {{ item.branch?.name }}
            </div>
          </template>
          <template #cell(date_end)="{ item }">
            <p style="margin: 0">
              {{ format(item?.date_end) }}
            </p>
          </template>

          <template #cell(date_start)="{ item }">
            <p style="margin: 0">
              {{ format(item?.date_start) }}
            </p>
          </template>
          <template #cell(sum)="{ item }">
            <div style="margin: 0; height: 30px">
              <div
                v-if="
                  currentSubInput != null &&
                  currentSubInput === item?.branch?.id
                "
              >
                <b-input
                  :value="item?.branch?.subscription?.sum"
                  size="sm"
                  @input="(val) => updateSub(item?.branch, val)"
                />
              </div>
              <div v-else @click="currentSubInput = item?.branch?.id">
                {{ formatMoney(item?.branch?.subscription?.sum) }}
              </div>
            </div>
          </template>

          <template #cell(branches_count)="{ item }">
            {{ item.branches_count ?? 0 }}
          </template>
          <template #cell(is_demo)="{ item }">
            <b-checkbox :checked="item.is_demo" disabled />
          </template>
          <template #cell(is_active)="{ item }">
            <b-checkbox :checked="item.is_active" disabled />
          </template>
          <template #cell(count_checks)="{ item }">
            {{ formatMoney(item.cmp_stats.count) }}
          </template>
          <template #cell(avg_checks)="{ item }">
            {{ formatMoney(item.cmp_stats.avg) }}
          </template>
          <template #cell(ltv)="{ item }">
            {{ formatMoney(item.cmp_stats.ltv) }}
          </template>
          <template #cell(sudo_entities)="{ item }">
            <div v-if="item.company?.id && !item.branch?.id" @click.stop>
              <e-select
                :options="entites"
                :value="fmtCmp(item.company?.sudo_entities)"
                multiple
                @change="(value) => updateCmp(value, item)"
              />
            </div>
          </template>
        </b-table>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import BarChart from "@/views/components/BarChart.vue";
import { tooltipBuilder } from "@/views/js/tooltip";
import { SubTooltipBuilder } from "@/views/js/sub_tooltip";
export default {
  name: "HomeView",
  components: { BarChart },

  apollo: {
    SudoEntities: {
      query: require("./gql/SudoEntities.graphql"),
      fetchPolicy: "no-cache",
      result({ data }) {
        this.entites = data.SudoEntities || [];
      },
    },
    CompanyWidgetAnalytics: {
      query: require("./gql/CompanyWidgetAnalytics.graphql"),
      fetchPolicy: "no-cache",
      result({ data }) {
        this.widget = data.CompanyWidgetAnalytics;
      },
    },
    BranchAnal: {
      query: require("./gql/BranchAnal.graphql"),
      fetchPolicy: "no-cache",
      variables() {
        const begin = new Date();
        begin.setFullYear(begin.getFullYear() - 1);
        return {
          input: {
            begin: begin,
            end: new Date(),
          },
        };
      },
      result({ data }) {
        console.log(data);
        const chart_data = JSON.parse(JSON.stringify(this.branchChartData));
        chart_data.datasets[0].data = data.BranchAnal.map((el) => {
          return { x: el.date, y: el.count || 0, franchise: el.franchise };
        });
        this.branchChartData = chart_data;
      },
    },
    SubAnal: {
      query: require("./gql/SubAnal.graphql"),
      fetchPolicy: "no-cache",
      variables() {
        const date = new Date();
        date.setMonth(date.getMonth() - 1);
        return {
          input: {
            begin: date,
            end: "3000-01-01",
          },
        };
      },
      result({ data }) {
        console.log(data);
        const chart_data = JSON.parse(JSON.stringify(this.subChartData));
        chart_data.datasets[0].data = data.SubAnal.map((el) => {
          return {
            x: el.date,
            y: el.count || 0,
            franchise_list: el.franchise_list,
          };
        });
        this.subChartData = chart_data;
      },
    },
    CompanyAnalytics: {
      query: require("./gql/CompanyAnalytics.graphql"),
      debounce: 300,
      variables: function () {
        return {
          input: this.input,
        };
      },
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
      result({ data }) {
        if (this.index === -1) {
          this.index = 0;
          this.copy_companies = [];
        }
        const items = data?.CompanyAnalytics?.list ?? [];
        const item = this.copy_companies[this.index - 1] || {};
        let count = 0,
          avg = 0,
          ltv = 0;
        items.forEach((el) => {
          el.order = item.order ? item.order + "." + el.order : el.order;
          el.id = Math.random();
          count += el.cmp_stats?.count || 0;
          ltv += el.cmp_stats?.ltv || 0;
        });
        avg = ltv / count || 0;
        item.cmp_stats = {
          count,
          ltv: ltv / items.filter((el) => el.cmp_stats?.ltv).length,
          avg,
        };
        this.copy_companies.splice(this.index, 0, ...items);
        if (this.copy_companies[this.index - 1])
          this.copy_companies[this.index - 1].child_length =
            data?.CompanyAnalytics?.list?.length;

        this.total_items = data?.AdmCompaniesV2?.total_alive ?? 0;
        this.table.isBusy = false;
        this.isLoading = false;
      },
      error() {},
    },
  },

  data() {
    return {
      isAlive: false,
      currentSubInput: null,
      sub_timer: null,
      show_franhise_input: null,
      total_items: 0,
      index: -1,
      isFasol: false,
      plugin: {
        tooltip: {
          enabled: false,
          position: "average",
          external: tooltipBuilder,
        },
      },
      sub_plugin: {
        tooltip: {
          enabled: false,
          external: SubTooltipBuilder,
          yAlign: "top",
        },
      },
      isLoading: false,
      subChartData: {
        labels: [],
        datasets: [
          {
            label: "План сумма",
            backgroundColor: "rgba(128,179,255,0.8)",
            borderColor: "rgba(128,179,255,0.8)",
            data: [],
          },
        ],
      },
      branchChartData: {
        labels: [],
        datasets: [
          {
            label: "Магазины кол-во",
            backgroundColor: "rgba(128,179,255,0.8)",
            borderColor: "rgba(128,179,255,0.8)",
            data: [],
          },
        ],
      },
      widget: {
        br_count: 0,
        br_sub_active: 0,
        cmp_active: 0,
        cmp_count: 0,
      },
      input: {
        company: null,
        franchise: null,
        sub_end_after: new Date(),
      },
      franchise_options: [
        { value: "free", text: "free" },
        { value: "fasol", text: "Фасоль" },
        { value: "fc", text: "Фермер-центр" },
        { value: "fo", text: "Фермерскйи островок" },
        { value: "svc", text: "Сервисные" },
      ],
      entites: [],
      table: {
        fields: [
          {
            key: "order",
            label: "№",
            thClass: "align-middle",
            thStyle: "width: 50px;",
            tdClass: "text-right",
            checked: true,
            width: 80,
          },
          {
            key: "franchise",
            label: "Проект",
            thClass: "text-left",
            style: "",
            width: 150,
            thStyle: "width: 250px; text-align: left;",
            tdClass: "text-left",
            class: "position-relative text-left",
            checked: true,
          },
          {
            key: "company",
            label: "Компания",
            thClass: "align-middle",
            style: "",
            width: 250,
            thStyle: "width: 250px;",
            tdClass: "text-left",
            class: "position-relative",
            checked: true,
          },
          {
            key: "branch",
            label: "Магазин",
            thClass: "align-middle",
            style: "",
            width: 250,
            thStyle: "width: 250px;",
            tdClass: "text-left",
            class: "position-relative",
            checked: true,
          },
          {
            key: "sum",
            label: "Цена подписки",
            width: 300,
            thStyle: "width: 150px;",
            thClass: "align-middle",
            tdClass: "text-right",
            style: "width: 50px",
            checked: true,
          },
          {
            key: "branch_quantity",
            label: "Кол-во магазинов",
            width: 300,
            thStyle: "width: 150px;",
            thClass: "align-middle",
            tdClass: "text-right",
            style: "width: 50px",
            checked: true,
          },
          {
            key: "date_start",
            label: "Дата начала",
            width: 200,
            sortable: true,
            thClass: "align-middle",
            thStyle: "width: 150px;",
            checked: true,
          },
          {
            key: "date_end",
            label: "Дата окончания",
            width: 200,
            thClass: "align-middle",
            thStyle: "width: 150px;",
            checked: true,
          },
          {
            key: "is_demo",
            label: "Триальный",
            width: 100,
            thClass: "align-middle",
            thStyle: "width: 100px;",
            checked: true,
          },
          {
            key: "is_active",
            label: "Активный",
            width: 100,
            thClass: "align-middle",
            thStyle: "width: 100px;",
            checked: true,
          },
          {
            key: "count_checks",
            label: "Кол-во чеков",
            width: 100,
            thClass: "align-middle",
            thStyle: "width: 100px;",
            checked: true,
          },
          {
            key: "avg_checks",
            label: "Ср. Чек",
            width: 100,
            thClass: "align-middle",
            thStyle: "width: 100px;",
            checked: true,
          },
          {
            key: "ltv",
            label: "LTV",
            width: 100,
            thClass: "align-middle",
            thStyle: "width: 100px;",
            checked: true,
          },
          {
            key: "sudo_entities",
            label: "Контрагент",
            checked: true,
          },
          {
            key: "#",
            label: "",
            checked: true,
          },
        ],
        isBusy: true,
      },
      copy_companies: [],
      showBlocked: false,
      company: null,
      franchise: null,
    };
  },
  watch: {
    isFasol() {
      this.table.isBusy = true;
    },
  },
  methods: {
    updateSub(branch, value) {
      if (!branch.subscription) {
        branch.subscription = { sum: 0 };
      }
      branch.subscription.sum = value;
      clearTimeout(this.sub_timer);
      this.sub_timer = setTimeout(() => {
        this.$apollo.mutate({
          mutation: require("./gql/UpdateSubscription.graphql"),
          variables: {
            input: {
              branch: branch.id,
              sum: +value,
            },
          },
        });
      }, 500);
    },
    changeDate(value) {
      this.input.sub_end_after = new Date(value);
      this.input.company = null;
      this.input.franchise = null;
      this.index = -1;
      this.$apollo.queries.CompanyAnalytics.refetch();
    },

    updateBranchFranchise(value, branch) {
      this.$apollo.mutate({
        mutation: require("./gql/UpdateBranchFranchise.graphql"),
        variables: {
          id: branch,
          franchise: value,
        },
      });
    },
    formatMoney(value) {
      let minimumFractionDigits = 2;

      if (!Number.isInteger(minimumFractionDigits)) minimumFractionDigits = 0;
      if (!value) value = 0;
      const formatter = new Intl.NumberFormat("ru-RU", {
        style: "decimal",
        currency: "RUB",
        maximumFractionDigits: 2,
        minimumFractionDigits: minimumFractionDigits,
      });

      return formatter.format(value);
    },
    rowClicked(item) {
      if (item.branch?.id) {
        this.show_franhise_input = item.branch?.id;
        return;
      }
      if (item.is_opened) {
        this.copy_companies = this.copy_companies.filter(
          (el) => !el.order?.toString()?.includes(item.order + ".")
        );
        item.is_opened = false;
        item.child_length = 0;
        return;
      }
      if (this.isLoading) return;

      this.index = this.copy_companies.findIndex((el) => item.id === el.id) + 1;

      this.isLoading = true;

      item.is_opened = true;

      this.input.company = item.company?.id;
      this.input.franchise = item.franchise;
      this.$apollo.queries.CompanyAnalytics.refetch();
    },
    format(dt) {
      return moment(new Date(dt)).format("DD.MM.YYYY");
    },

    handleSort(ctx) {
      let a1 = 1;
      let b2 = -1;
      if (ctx.sort === "DESC") {
        a1 = -1;
        b2 = 1;
      }
      if (ctx.key === "createdDate")
        this.companies.sort((a, b) =>
          a.createdDate > b.createdDate ? a1 : b2
        );
      if (ctx.key === "revenue")
        this.companies.sort((a, b) =>
          a.checkAnalytics?.revenue > b.checkAnalytics?.revenue ? a1 : b2
        );
      if (ctx.key === "margin")
        this.companies.sort((a, b) =>
          a.margin?.margin > b.margin?.margin ? a1 : b2
        );
      if (ctx.key === "avgMargin")
        this.companies.sort((a, b) =>
          a.analyticsMargin?.totalSum?.avgMargin >
          b.analyticsMargin?.totalSum?.avgMargin
            ? a1
            : b2
        );
      if (ctx.key === "margin_total")
        this.companies.sort((a, b) =>
          a.analyticsMargin?.totalSum?.margin >
          b.analyticsMargin?.totalSum?.margin
            ? a1
            : b2
        );
      if (ctx.key === "quantity")
        this.companies.sort((a, b) =>
          a.checkAnalytics?.quantity > b.checkAnalytics?.quantity ? a1 : b2
        );
      if (ctx.key === "average")
        this.companies.sort((a, b) =>
          a.checkAnalytics?.average > b.checkAnalytics?.average ? a1 : b2
        );
    },
    formatDate(date) {
      return date
        ? new Intl.DateTimeFormat("ru-RU").format(new Date(date))
        : "-";
    },
    updateCmp(value, item) {
      this.$apollo.mutate({
        mutation: require("./gql/SetSudoEntity.graphql"),
        variables: {
          input: {
            id: item.company?.id,
            sudo_entities: value?.map((el) => el.id),
          },
        },
      });
    },
    fmtCmp(value) {
      return value?.map((el) => {
        return {
          id: el,
        };
      });
    },
  },
  computed: {
    tableData() {
      return this.companies;
    },
    dateFormat() {
      return moment(this.input.sub_end_after).format("YYYY-MM-DD");
    },

    companies() {
      if (this.isAlive) {
        return this.copy_companies.filter((obj) => obj.is_alive === true);
      } else {
        return this.copy_companies;
      }
    },
  },
};
</script>

<style lang="scss">
.b-status {
  width: 2px !important;
  height: 32px !important;
  position: absolute;
  left: 0px;
  top: 50%;
  background: #bcbcbc;
  border-radius: 0px 1px 1px 0px;
  transform: translate(0%, -50%);

  &.success {
    background: #00cb91;
  }
}

.table-responsive {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 10px;
  }
}

.table-font * {
  font-size: 14px;
}

.b-table {
  table-layout: fixed;
  width: 2500px !important;
}

.b-table-sticky-header {
  max-height: 86vh !important;
}

.filters-block {
  display: flex;
}

.td-inner {
  position: relative;
}

.row {
  display: table-row !important;
}

.row-field {
  position: relative;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.widget {
  margin-right: 16px;
  align-items: start;
  justify-content: start;
  display: flex;
  flex-direction: column;
  text-align: start;

  .counter {
    margin-top: 16px;
  }
}
#chartjs-tooltip {
  z-index: 100000 !important;
}
</style>
