var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_c('notifications', {
    attrs: {
      "group": "foo",
      "position": "bottom right"
    }
  }), _c('nav', [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "justify-content": "space-between"
    }
  }, [_vm.isLogged ? _c('div', [_c('router-link', {
    staticStyle: {
      "margin-right": "15px"
    },
    attrs: {
      "to": "/"
    }
  }, [_vm._v("Главная")]), _c('router-link', {
    attrs: {
      "to": "/analytics"
    }
  }, [_vm._v("Анал")])], 1) : _vm._e(), _vm.isLogged ? _c('b-button', {
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": _vm.logout
    }
  }, [_vm._v("Выйти")]) : _vm._e()], 1)]), _c('router-view')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }