<template>
  <b-container>
    <div class="login">
      <div style="font-weight: 500; font-size: 32px" class="mt-lg-5">
        Авторизация
      </div>
      <b-form-input
        placeholder="Email"
        v-model="user.email"
        @input="validateMail"
        style="margin-top: 48px; border-radius: 4.8px"
        class="number-input"
      />
      <b-form-input
        placeholder="Пароль"
        type="password"
        v-on:keyup.enter="authorize"
        v-model="user.password"
        @input="validateMail"
        style="margin-top: 24px"
      />
      <div
        style="
          text-align: start;
          margin-top: 4px;
          display: flex;
          flex-direction: row;
        "
      ></div>
      <b-button style="margin-top: 40px" @click="authorize">Войти</b-button>
    </div>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LoginView",
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      validEmail: true,
    };
  },
  methods: {
    ...mapActions({
      login: "auth/login",
    }),
    remindPassword() {
      console.log("test");
    },
    validateMail() {
      this.validEmail = !(
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(this.user.email) &&
        this.user.password.length > 0
      );
    },
    async authorize() {
      await this.login(this.user);

      if (this.isLoggedIn) await this.$router.push("/");
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
    }),
  },
};
</script>

<style scoped>
.login {
  padding: 64px;
  display: flex;
  flex: 1;
  align-items: stretch;
  flex-direction: column;
}

.invalid-input {
  border: 1px solid #dc3545;
  border-radius: 4.8px;
}
</style>
