import { apolloClient } from "@/apollo.config";
import { LOGIN_USER, REGISTER_USER } from "@/store/auth/gql/mutations";

export default {
  namespaced: true,
  state: () => ({
    isLoggedIn: !!localStorage.getItem("admin_token"),
  }),
  mutations: {
    set_user(state, data) {
      state.user = data.Login.user;
      localStorage.setItem("admin_token", "Bearer " + data.Login.token);
      state.isLoggedIn = true;
    },
    set_current_user(state, data) {
      state.user = data;
    },
    invalid_login(state, data) {
      state.invalid = data;
    },
    logout(state) {
      state.isLoggedIn = false;
      localStorage.removeItem("admin_token");
      apolloClient.cache.data.clear();
    },
  },
  actions: {
    login: async ({ commit }, payload) => {
      try {
        const { data } = await apolloClient.mutate({
          mutation: LOGIN_USER,
          variables: {
            email: payload.email,
            password: payload.password,
          },
        });
        // console.log(data.Login,'user')
        commit("set_user", data);
      } catch (e) {
        console.log(e);
        commit("invalid_login", false);
      }
    },
    register_user: async (_data, payload) => {
      try {
        const { data } = await apolloClient.mutate({
          mutation: REGISTER_USER,
          variables: {
            email: payload.email,
            phone: payload.phone,
            first_name: payload.name,
            last_name: payload.surname,
            password: payload.password,
            branches: payload.branches ?? [],
            roles: payload.roles ?? [],
          },
        });
        if (data) await this.$router.push("/login");
      } catch (e) {
        console.log(e);
      }
    },
    logout: async ({ commit }) => {
      commit("logout");
    },
  },
  getters: {
    user: (state) => state.user,
    invalid: (state) => state.invalid,
    isLoggedIn: (state) => state.isLoggedIn,
  },
};
