<template>
  <div class="home">
    <b-container class="mb-3 table-font" fluid>
      <div
        style="
          display: flex;
          flex-direction: row;
          align-self: flex-start;
          cursor: pointer;
        "
        @click="setBlocked"
      >
        <b-checkbox switch class="p-0" v-model="showBlocked"></b-checkbox>
        Показывать заблокированные
      </div>
      <b-card no-body style="height: 86vh; padding-bottom: 0 !important">
        <b-table
          sticky-header
          style="height: 85vh; max-height: 85vh; margin-bottom: 0"
          :fields="table.fields"
          :items="tableData"
          label-sort-asc=""
          label-sort-desc=""
          label-sort-clear=""
          @sort-changed="handleSort"
          small
          hover
          responsive
          :busy="table.isBusy"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Загрузка...</strong>
            </div>
          </template>

          <template #cell(email)="{ item }">
            <p
              style="margin: 0; cursor: pointer"
              @click="generateStorage(item.user.email)"
            >
              {{ item.user?.email ?? "-" }}
            </p>
          </template>

          <template #cell(phone)="{ item }">
            <p
              style="margin: 0; cursor: pointer"
              @click="generateStorage(item.user.phone)"
            >
              {{ item.user?.phone ?? "-" }}
            </p>
          </template>

          <template #cell(lastActivity)="{ item }">
            <p style="margin: 0">
              {{
                item.user?.lastActivity
                  ? formatDateTime(item.user.lastActivity)
                  : "-"
              }}
            </p>
          </template>
          <template #cell(active)="{ item }">
            <p style="margin: 0">
              <b-form-checkbox
                :checked="item.cash_integration?.active"
                disabled
              />
            </p>
          </template>
          <template #cell(cancel_date)="{ item }">
            <p style="margin: 0">
              {{
                item.cash_integration?.cancel_date
                  ? formatDateTime(item.cash_integration?.cancel_date)
                  : "-"
              }}
            </p>
          </template>

          <template #cell(block)="{ item }">
            <b-icon
              icon="lock-fill"
              v-if="!item.blocked"
              style="cursor: pointer; color: #dc3545"
              @click="blockCompany(item.id)"
              scale="1.3"
            />
            <b-icon
              icon="unlock-fill"
              v-if="item.blocked"
              style="cursor: pointer; color: #00ff3b"
              @click="blockCompany(item.id)"
              scale="1.3"
            />
          </template>
        </b-table>
      </b-card>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src

import gql from "graphql-tag";

export default {
  name: "HomeView",
  components: {},

  apollo: {
    AdmCompanies: {
      query: gql`
        query AdmComp($blocked: Boolean!) {
          AdmCompanies(blocked: $blocked) {
            id
            name
            user {
              email
              phone
              lastActivity
            }
            blocked
            createdDate
            cash_integration {
              active
              cancel_date
            }
          }
        }
      `,
      variables() {
        return {
          blocked: this.showBlocked,
        };
      },
      fetchPolicy: "no-cache",
      result({ data }) {
        this.companies = data?.AdmCompanies ?? [];
        this.table.isBusy = false;
      },
      error() {
        alert("Ошибка получения");
      },
    },
  },

  data() {
    return {
      table: {
        fields: [
          { key: "name", label: "Название компании", thClass: "align-middle" },
          {
            key: "email",
            label: "Email",
            thClass: "align-middle",
          },
          {
            key: "phone",
            label: "Тел",
            thClass: "align-middle",
          },
          {
            key: "createdDate",
            label: "Рег.",
            formatter: "formatDate",
            sortable: true,
            thClass: "align-middle",
          },
          {
            key: "lastActivity",
            label: "Послед.актив",
            thClass: "align-middle",
          },
          {
            key: "active",
            label: "Активно",
            thClass: "align-middle",
            sortable: true,
          },
          {
            key: "cancel_date",
            label: "Дата Кон",
            sortable: true,
            thClass: "align-middle",
          },
          { key: "block", label: "Блок", thClass: "align-middle" },
        ],
        isBusy: true,
      },
      companies: [],
      showBlocked: false,
    };
  },
  methods: {
    handleSort(ctx) {
      let a1 = 1;
      let b2 = -1;
      if (ctx.sortDesc) {
        a1 = -1;
        b2 = 1;
      }
      if (ctx.sortBy === "revenue")
        this.companies.sort((a, b) =>
          a.checkAnalytics?.revenue > b.checkAnalytics?.revenue ? a1 : b2
        );
      if (ctx.sortBy === "margin")
        this.companies.sort((a, b) =>
          a.margin?.margin > b.margin?.margin ? a1 : b2
        );
      if (ctx.sortBy === "avgMargin")
        this.companies.sort((a, b) =>
          a.analyticsMargin?.totalSum?.avgMargin >
          b.analyticsMargin?.totalSum?.avgMargin
            ? a1
            : b2
        );
      if (ctx.sortBy === "margin_total")
        this.companies.sort((a, b) =>
          a.analyticsMargin?.totalSum?.margin >
          b.analyticsMargin?.totalSum?.margin
            ? a1
            : b2
        );
      if (ctx.sortBy === "quantity")
        this.companies.sort((a, b) =>
          a.checkAnalytics?.quantity > b.checkAnalytics?.quantity ? a1 : b2
        );
      if (ctx.sortBy === "average")
        this.companies.sort((a, b) =>
          a.checkAnalytics?.average > b.checkAnalytics?.average ? a1 : b2
        );
      if (ctx.sortBy === "active")
        this.companies.sort((a, b) =>
          !!a.cash_integration?.active > !!b.cash_integration?.active ? a1 : b2
        );
      if (ctx.sortBy === "cancel_date")
        this.companies.sort((a, b) =>
          new Date(a.cash_integration?.cancel_date) >
          new Date(b.cash_integration?.cancel_date)
            ? a1
            : b2
        );
    },
    formatDate(date) {
      return new Intl.DateTimeFormat("ru-RU").format(new Date(date));
    },

    formatDateTime(date) {
      return new Intl.DateTimeFormat("ru-RU", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format(new Date(date));
    },

    setBlocked() {
      this.showBlocked = !this.showBlocked;
    },
    async blockCompany(id) {
      await this.$apollo.mutate({
        mutation: gql`
          mutation BlockCompany($id: ID!) {
            BlockCompany(id: $id) {
              status
            }
          }
        `,
        variables: { id: id },
      });
      await this.$apollo.queries.AdmCompanies.refetch();
    },
    async generateStorage(email) {
      try {
        const { data } = await this.$apollo.query({
          query: gql`
            query LocalStorage($email: String!) {
              LocalStorage(email: $email, phone: $email)
            }
          `,
          variables: {
            email,
          },
        });
        window.open(
          "https://online.entersight.ru/setToken?token=" +
            JSON.parse(data.LocalStorage)?.core?.token
        );
        this.$notify({
          group: "foo",
          type: "success",
          title: "Успешно",
          text: "LocalStorage скопирован",
        });
      } catch (e) {
        console.log(e);

        this.$notify({
          group: "foo",
          type: "error",
          title: "Ошибка",
          text: "Ошибка создания LocalStorage",
        });
      }
    },
  },
  computed: {
    tableData() {
      return this.companies;
    },
  },
};
</script>

<style>
.table-font * {
  font-size: 14px;
}
</style>
