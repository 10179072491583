import gql from "graphql-tag";

export const LOGIN_USER = gql`
  mutation LoginUser($email: String, $password: String!) {
    Login(input: { email: $email, password: $password }) {
      token
      user {
        id
        first_name
        second_name
        last_name
        phone
        email
      }
    }
  }
`;

export const REGISTER_USER = gql`
  mutation RegisterUser(
    $email: String
    $phone: String
    $first_name: String!
    $last_name: String!
    $password: String!
    $branches: [ID]
    $roles: [String]
  ) {
    Register(
      input: {
        email: $email
        phone: $phone
        first_name: $first_name
        last_name: $last_name
        password: $password
        branches: $branches
        roles: $roles
      }
    ) {
      token
      user {
        id
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser(
    $email: String
    $phone: String
    $first_name: String!
    $last_name: String!
    $password: String!
    $branches: [ID]
    $roles: [String]
  ) {
    CreateUser(
      input: {
        email: $email
        phone: $phone
        first_name: $first_name
        last_name: $last_name
        password: $password
        branches: $branches
        roles: $roles
      }
    ) {
      id
    }
  }
`;
