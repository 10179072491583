var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "home"
  }, [_c('b-container', {
    staticClass: "mb-3 table-font",
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "d-flex mb-3",
    staticStyle: {
      "margin-right": "16px"
    }
  }, [_c('b-card', {
    staticClass: "widget"
  }, [_c('div', [_vm._v("Кол-во аккаунтов")]), _c('div', {
    staticClass: "counter"
  }, [_vm._v(_vm._s(_vm.widget.cmp_count))])]), _c('b-card', {
    staticClass: "widget"
  }, [_c('div', [_vm._v("Кол-во магазинов")]), _c('div', {
    staticClass: "counter"
  }, [_vm._v(_vm._s(_vm.widget.br_count))])]), _c('b-card', {
    staticClass: "widget"
  }, [_c('div', [_vm._v("Кол-во подписаных магазинов")]), _c('div', {
    staticClass: "counter"
  }, [_vm._v(_vm._s(_vm.widget.br_sub_active))])]), _c('b-card', {
    staticClass: "widget"
  }, [_c('div', [_vm._v("Кол-во активных аккаунтов")]), _c('div', {
    staticClass: "counter"
  }, [_vm._v(_vm._s(_vm.widget.cmp_active))])]), _c('b-card', {
    staticClass: "widget"
  }, [_c('div', [_vm._v("Средний LTV")]), _c('div', {
    staticClass: "counter"
  }, [_vm._v("6")])])], 1), _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticStyle: {
      "width": "500px",
      "height": "200px",
      "z-index": "3"
    }
  }, [_c('BarChart', {
    attrs: {
      "chart-data": _vm.subChartData,
      "plugins": _vm.sub_plugin
    }
  })], 1), _c('div', {
    staticStyle: {
      "width": "500px",
      "height": "200px",
      "z-index": "3"
    }
  }, [_c('BarChart', {
    attrs: {
      "chart-data": _vm.branchChartData,
      "plugins": _vm.plugin
    }
  })], 1)]), _c('b-card', {
    staticClass: "anal-table",
    staticStyle: {
      "height": "86vh",
      "padding-bottom": "0 !important"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "p-3 align-items-center",
    staticStyle: {
      "display": "flex"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-start",
    staticStyle: {
      "margin-right": "16px"
    }
  }, [_c('b-checkbox', [_vm._v(" Показывать демо")])], 1), _c('div', {
    staticStyle: {
      "margin-right": "16px"
    }
  }, [_c('b-checkbox', [_vm._v(" Показывать активные")])], 1), _c('div', {
    staticClass: "d-flex flex-column align-items-start"
  }, [_vm._v(" Окончание после: "), _c('b-input', {
    attrs: {
      "type": "date",
      "value": _vm.dateFormat,
      "autofocus": ""
    },
    on: {
      "input": _vm.changeDate
    }
  })], 1)]), _c('b-table', {
    attrs: {
      "sticky-header": "",
      "fields": _vm.table.fields,
      "items": _vm.companies,
      "label-sort-asc": "",
      "label-sort-desc": "",
      "label-sort-clear": "",
      "small": "",
      "hover": "",
      "bordered": "",
      "striped": "",
      "responsive": "",
      "busy": _vm.table.isBusy,
      "table_name": "sss4234ы"
    },
    on: {
      "sort_change": _vm.handleSort,
      "row-clicked": _vm.rowClicked
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle"
        }), _c('strong', [_vm._v("Загрузка...")])], 1)];
      },
      proxy: true
    }, {
      key: "cell(number)",
      fn: function (data) {
        var _ref;

        return [_c('div', [_c('div', {
          staticClass: "b-status",
          class: {
            success: data.item.is_alive
          }
        }), _c('p', {
          staticStyle: {
            "margin": "0",
            "cursor": "pointer"
          }
        }, [_vm._v(" " + _vm._s((_ref = data.index + 1) !== null && _ref !== void 0 ? _ref : "-") + " ")])])];
      }
    }, {
      key: "cell(franchise)",
      fn: function (_ref2) {
        var _item$branch;

        var item = _ref2.item;
        return [_c('div', [_vm._v(" " + _vm._s(item.company ? "" : item.franchise) + " ")]), _vm.show_franhise_input === ((_item$branch = item.branch) === null || _item$branch === void 0 ? void 0 : _item$branch.id) ? _c('div', [_c('b-select', {
          attrs: {
            "options": _vm.franchise_options
          },
          on: {
            "input": function (value) {
              var _item$branch2;

              return _vm.updateBranchFranchise(value, (_item$branch2 = item.branch) === null || _item$branch2 === void 0 ? void 0 : _item$branch2.id);
            }
          },
          model: {
            value: item.franchise,
            callback: function ($$v) {
              _vm.$set(item, "franchise", $$v);
            },
            expression: "item.franchise"
          }
        })], 1) : _vm._e()];
      }
    }, {
      key: "cell(company)",
      fn: function (_ref3) {
        var _item$company;

        var item = _ref3.item;
        return [_c('div', [_vm._v(" " + _vm._s(item.branch ? "" : (_item$company = item.company) === null || _item$company === void 0 ? void 0 : _item$company.name) + " ")])];
      }
    }, {
      key: "cell(branch)",
      fn: function (_ref4) {
        var _item$branch3;

        var item = _ref4.item;
        return [_c('div', [_vm._v(" " + _vm._s((_item$branch3 = item.branch) === null || _item$branch3 === void 0 ? void 0 : _item$branch3.name) + " ")])];
      }
    }, {
      key: "cell(date_end)",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('p', {
          staticStyle: {
            "margin": "0"
          }
        }, [_vm._v(" " + _vm._s(_vm.format(item === null || item === void 0 ? void 0 : item.date_end)) + " ")])];
      }
    }, {
      key: "cell(date_start)",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('p', {
          staticStyle: {
            "margin": "0"
          }
        }, [_vm._v(" " + _vm._s(_vm.format(item === null || item === void 0 ? void 0 : item.date_start)) + " ")])];
      }
    }, {
      key: "cell(sum)",
      fn: function (_ref7) {
        var _item$branch4, _item$branch5, _item$branch5$subscri, _item$branch7, _item$branch7$subscri;

        var item = _ref7.item;
        return [_c('div', {
          staticStyle: {
            "margin": "0",
            "height": "30px"
          }
        }, [_vm.currentSubInput != null && _vm.currentSubInput === (item === null || item === void 0 ? void 0 : (_item$branch4 = item.branch) === null || _item$branch4 === void 0 ? void 0 : _item$branch4.id) ? _c('div', [_c('b-input', {
          attrs: {
            "value": item === null || item === void 0 ? void 0 : (_item$branch5 = item.branch) === null || _item$branch5 === void 0 ? void 0 : (_item$branch5$subscri = _item$branch5.subscription) === null || _item$branch5$subscri === void 0 ? void 0 : _item$branch5$subscri.sum,
            "size": "sm"
          },
          on: {
            "input": function (val) {
              return _vm.updateSub(item === null || item === void 0 ? void 0 : item.branch, val);
            }
          }
        })], 1) : _c('div', {
          on: {
            "click": function ($event) {
              var _item$branch6;

              _vm.currentSubInput = item === null || item === void 0 ? void 0 : (_item$branch6 = item.branch) === null || _item$branch6 === void 0 ? void 0 : _item$branch6.id;
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.formatMoney(item === null || item === void 0 ? void 0 : (_item$branch7 = item.branch) === null || _item$branch7 === void 0 ? void 0 : (_item$branch7$subscri = _item$branch7.subscription) === null || _item$branch7$subscri === void 0 ? void 0 : _item$branch7$subscri.sum)) + " ")])])];
      }
    }, {
      key: "cell(branches_count)",
      fn: function (_ref8) {
        var _item$branches_count;

        var item = _ref8.item;
        return [_vm._v(" " + _vm._s((_item$branches_count = item.branches_count) !== null && _item$branches_count !== void 0 ? _item$branches_count : 0) + " ")];
      }
    }, {
      key: "cell(is_demo)",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_c('b-checkbox', {
          attrs: {
            "checked": item.is_demo,
            "disabled": ""
          }
        })];
      }
    }, {
      key: "cell(is_active)",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_c('b-checkbox', {
          attrs: {
            "checked": item.is_active,
            "disabled": ""
          }
        })];
      }
    }, {
      key: "cell(count_checks)",
      fn: function (_ref11) {
        var item = _ref11.item;
        return [_vm._v(" " + _vm._s(_vm.formatMoney(item.cmp_stats.count)) + " ")];
      }
    }, {
      key: "cell(avg_checks)",
      fn: function (_ref12) {
        var item = _ref12.item;
        return [_vm._v(" " + _vm._s(_vm.formatMoney(item.cmp_stats.avg)) + " ")];
      }
    }, {
      key: "cell(ltv)",
      fn: function (_ref13) {
        var item = _ref13.item;
        return [_vm._v(" " + _vm._s(_vm.formatMoney(item.cmp_stats.ltv)) + " ")];
      }
    }, {
      key: "cell(sudo_entities)",
      fn: function (_ref14) {
        var _item$company2, _item$branch8, _item$company3;

        var item = _ref14.item;
        return [(_item$company2 = item.company) !== null && _item$company2 !== void 0 && _item$company2.id && !((_item$branch8 = item.branch) !== null && _item$branch8 !== void 0 && _item$branch8.id) ? _c('div', {
          on: {
            "click": function ($event) {
              $event.stopPropagation();
            }
          }
        }, [_c('e-select', {
          attrs: {
            "options": _vm.entites,
            "value": _vm.fmtCmp((_item$company3 = item.company) === null || _item$company3 === void 0 ? void 0 : _item$company3.sudo_entities),
            "multiple": ""
          },
          on: {
            "change": function (value) {
              return _vm.updateCmp(value, item);
            }
          }
        })], 1) : _vm._e()];
      }
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }